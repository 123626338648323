
.tablefont{
    font-family: Roboto, Helvetica, Arial, sans-serif;  ;
    font-size: 14px !important ;
    font-weight: 500;
    line-height: 15px;
}
.tableRow button{
    height: 44px;
    width: 47px;
    padding: 5px;
    border-radius: 50%;
    /* color: white; */
    border: none;
    align-items: right;
}

.yourScore{
    background-color: #00BCD4;
    color: #ffff;
}
.avgScore{
    background-color: #F05B4F;
    color: #ffff;

}
.topScore{
    background-color: #F4C63D;
    color: #ffff;

}
.tableHead{
    /* background: lavender; */
}
.tableHeading{
    font-family: Roboto, Helvetica, Arial, sans-serif;  ;
    font-size: 14px !important ;
    font-weight: 500;
    line-height: 15px;
    float:left;
    width:84px;
    font-weight:700 ;
    text-align:center;
    color: #DE2668;
}